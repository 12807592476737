/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #000;
} */

.gallery {
  position: relative;
  width: 260px;
  height: 200px;
  transform-style: preserve-3d;
  animation: animate 25s linear infinite;
}

.datetext {
  font-size: 22px;
  margin: 0;
}

.playzopara {
  font-size: 12px;
  margin: 0;
}

.paratext {
  font-size: 12px;
  margin: 0;
}

.gallery span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-reflect: below 0px
    linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.5));
}

.gallery span:nth-child(9) {
  transform: rotateY(calc(1 * 45deg)) translateZ(400px);
}

.gallery span:nth-child(1) {
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.gallery span:nth-child(2) {
  transform: rotateY(calc(2 * 45deg)) translateZ(400px);
}

.gallery span:nth-child(3) {
  transform: rotateY(calc(3 * 45deg)) translateZ(400px);
}

.gallery span:nth-child(4) {
  transform: rotateY(calc(4 * 45deg)) translateZ(400px);
}

.gallery span:nth-child(5) {
  transform: rotateY(calc(5 * 45deg)) translateZ(400px);
}

.gallery span:nth-child(6) {
  transform: rotateY(calc(6 * 45deg)) translateZ(400px);
}

.gallery span:nth-child(7) {
  transform: rotateY(calc(7 * 45deg)) translateZ(400px);
}

.gallery span:nth-child(8) {
  transform: rotateY(calc(8 * 45deg)) translateZ(400px);
}

.gallery span img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes animate {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

.inner_text {
  position: absolute;
  top: 70%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for perfect centering */
  text-align: center;
  width: 100%;
  color: white;
  font-weight: 700;
  font-family: lucoCleanBold;
}

.inner_text p {
  padding: 10px;
}

@media (min-width: 300px) and (max-width: 767px) {
  .gallery {
    width: 150px;
    height: 130px;
  }

  .datetext {
    font-size: 17px;
  }

  .playzopara {
    font-size: 11px;
  }

  .paratext {
    font-size: 11px;
  }

  .gallery span:nth-child(9) {
    transform: rotateY(calc(1 * 45deg)) translateZ(200px);
  }

  .gallery span:nth-child(1) {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  .gallery span:nth-child(2) {
    transform: rotateY(calc(2 * 45deg)) translateZ(200px);
  }

  .gallery span:nth-child(3) {
    transform: rotateY(calc(3 * 45deg)) translateZ(200px);
  }

  .gallery span:nth-child(4) {
    transform: rotateY(calc(4 * 45deg)) translateZ(200px);
  }

  .gallery span:nth-child(5) {
    transform: rotateY(calc(5 * 45deg)) translateZ(200px);
  }

  .gallery span:nth-child(6) {
    transform: rotateY(calc(6 * 45deg)) translateZ(200px);
  }

  .gallery span:nth-child(7) {
    transform: rotateY(calc(7 * 45deg)) translateZ(200px);
  }

  .gallery span:nth-child(8) {
    transform: rotateY(calc(8 * 45deg)) translateZ(200px);
  }
}
