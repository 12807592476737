body {
  margin: 0;
  font-family: "Marcellus", "Daggersquare", "brandonText", "lucoCleanBold",
    "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Futurist Fixed-width"),
    url("./assets/Futurist-Fixed-width-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Montserrat"),
    url("./assets/Marcellus-Regular.ttf") format("truetype");
}

@font-face {
  font-family: DaggerSquare;
  src: url("./assets/DAGGERSQUARE.otf");
  font-display: swap;
}
@font-face {
  font-family: lucoCleanBold;
  src: url("./assets/LuloCleanW01OneBold.ttf");
}

@font-face {
  font-family: "Berkshire Swash";
  src: url("./assets/BerkshireSwash-Regular.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@font-face {
  font-family: brandonText;
  src: url("./assets/Brandon\ Text\ W01\ Light.ttf");
}

/* swiper */
swiper-container {
  width: 100%;
  flex-grow: 1;
}

swiper-container::part(pagination) {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

swiper-container::part(bullet) {
  display: none;
  width: 5px;
  height: 5px;
  border: 1px solid #fff;
  background-color: #05607d;
}

swiper-container::part(bullet-active) {
  display: none;
  width: 5px;
  height: 5px;
  border: 1px solid #fff;
  background-color: #fff;
}

swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}
.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  padding: 8px 16px;
  border-radius: 100%;
  border: 2px solid black;
  color: red;
}
.swiper-pagination-bullet {
  width: 40px;
  height: 40px;
  background-color: black;
}
/* .swiper-button-next svg{
  display: none;
} */

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.questions-container {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.questions-container.slide-up {
  animation: slideUp 1s forwards;
}

::-webkit-scrollbar {
  width: 10px;
  scroll-behavior: smooth;
}
::-webkit-scrollbar-track {
  background: black;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background: #15b5fc;
  border-radius: 100px;
}
@media (max-width: 767px) {
  .home_slider_text {
    font-size: 12px !important;
  }
}
.pdf-download-link {
  padding: 13px;
  text-transform: none;
  font-size: 14px;
  max-width: 350px;
  font-weight: 400;
  border: 2px solid #15b5fc;
  border-radius: 30px;
  letter-spacing: 1.6px;
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
  color: white;
  font-family: "Montserrat, sans-serif";
  transition: background 0.3s, color 0.3s, border 0.3s;
}

.pdf-download-link:hover {
  background: white;
  color: #15b5fc; /* Colors.BUTTON_COLOR */
  border: 2px solid #15b5fc;
}
