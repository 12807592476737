.mission-section {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 400px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.mission-section::before,
.mission-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #15b5fc; /* Replace with your desired border color */
  border-radius: 50%;
  border-top-width: 7px;
  border-bottom-width: 7px;
}

.mission-section::before {
  top: 3px;
  right: 32px;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform: rotate(24deg);
  width: 125px;
  height: 6px;
  background-color: #15b5fc;
  border-radius: 0px;
}

.mission-content {
  width: 75%;
  height: 75%;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}

.icon {
  font-size: 2.5rem;
}

h3 {
  margin-top: 17px;
  font-size: 26px;
  font-weight: 600;
}

@media (min-width: 300px) {
  .mission-vision-values {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
  }
  .mission-section {
    position: relative;
    width: 100%;
    max-width: 320px;
    height: 320px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
  }
}

@media (min-width: 1200px) {
  .mission-vision-values {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 0px;
    padding-bottom: 50px;
  }
  .mission-section {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 400px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
  }
}
